module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.15.0__react-do_87526d5ea0cf9674e7d85f0103c87c4e/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b5fb90186030d400815a77d9e854bac5"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-breakpoints@1.3.11_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.15.0__react_ff09089dc1e67dbaee390fe609743245/node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"isMobile":"(max-width: 428px)","isTablet":"(min-width: 429px)","isDesktop":"(min-width: 1025px)","isLarge":"(min-width: 1441px)"}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.15.0__react_a29585db60253bbbfca27c1aef47871e/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-6FZJTZDJ6Q"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.15.0__react-dom@18.3.1_react@18.3.1__react@1_6a9734ecd9a5d3bd9720ac059b31e1d3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
